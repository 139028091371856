import ImageWithLightbox from 'src/components/image-with-lightbox'

export const COURSE = {
    version: '1.0.3',
    terms: {
        content: {},
        questions: [
            {
                id: 'terms',
                type: 'single',
                content: '',
                title: 'Условия использования',
                answers: [
                    {
                        title: (
                            <div>
                                Я соглашаюсь с{' '}
                                <a
                                    target="_blank"
                                    href="https://dialog.x5.ru/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf">
                                    политикой обработки персональных данных
                                </a>
                                , а также на получение сообщений информационного
                                и рекламного характера
                            </div>
                        ),
                        isValid: true,
                        hint: '',
                    },
                ],
            },
        ],
    },
    intro: {
        content: {},
        questions: [
            {
                id: 'intro-01',
                type: 'single',
                content: '',
                title: 'Какой подход для анализа данных использует ваша компания?',
                answers: [
                    {
                        title: 'Заказываем готовые исследования с выводами',
                        isValid: true,
                        hint: '',
                    },
                    {
                        title: 'Анализируем данные самостоятельно',
                        isValid: true,
                        hint: '',
                        nextQuestion: 2, // index = 3 question
                    },
                    {
                        title: 'Используем оба подхода в равной степени',
                        isValid: true,
                        hint: '',
                        nextQuestion: 2, // index = 3 question
                    },
                ],
            },
            {
                id: 'intro-02',
                type: 'single',
                content: {},
                title: 'Почему используете готовую аналитику?',
                answers: [
                    {
                        title: 'Нет своего аналитического отдела',
                        isValid: true,
                        hint: '',
                    },
                    {
                        title: 'В целях экономии времени и ресурсов (в том числе на обработку данных)',
                        isValid: true,
                        hint: '',
                    },
                    {
                        title: 'Доверяем профессионалам в области аналитики',
                        isValid: true,
                        hint: '',
                    },
                    {
                        title: 'Рассматриваем внешние агентства, как источник новых идей и точек зрения',
                        isValid: true,
                        hint: '',
                    },
                    {
                        title: 'Другое',
                        isValid: true,
                        hint: '',
                    },
                ],
            },
            {
                id: 'intro-03',
                type: 'single',
                content: {},
                title: 'Как технически у вас устроена работа с данными?',
                answers: [
                    {
                        title: 'Хранилище развернуто на серверах компании, к которому подключена ВІ система',
                        isValid: true,
                        hint: '',
                    },
                    {
                        title: 'Данные хранятся в облаке, там и анализируем',
                        isValid: true,
                        hint: '',
                    },
                    {
                        title: 'Используем внешние аналитические сервисы, такие как...',
                        isValid: true,
                        hint: '',
                    },
                    {
                        title: 'Данные хранятся в виде Ехсе! файлов, в которых и проводится анализ нашими специалистами',
                        isValid: true,
                        hint: '',
                    },
                    {
                        title: 'Другое',
                        isValid: true,
                        hint: '',
                    },
                ],
            },
        ],
    },
    cases: [
        {
            content: {
                index: '01',
                title: 'Рост продаж через увеличение пенетрации, благодаря развитию дистрибуции и умеренному росту цены',
                description:
                    'В этом кейсе разберем основные метрики, которые нужно учитывать при анализе доли',
            },
            questions: [
                {
                    id: 'case-1-01',
                    type: 'multiple',
                    content: (
                        <div>
                            <p>
                                Представим самую приятную ситуацию для бренда,
                                когда оборот категории растёт и доля
                                анализируемого продукта тоже растёт! Откуда мы
                                это узнали? Конечно, из нашей аналитики.
                            </p>
                            <p>
                                Перед нами базовые метрики по категории. Мы
                                видим, что оборот категории вырос на 8,6%. Бренд
                                2, занимающий одну из лидирующих позиций,
                                увеличил продажи ещё сильнее (на +24,7%), что
                                позволило ему нарастить долю в категории.
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case1/case1_1.png'}
                                largeImage={'/images/case1/case1_1.png'}
                            />
                            <p>
                                Конечно, мы радуемся таким результатам, но в то
                                же самое время нам важно понять, за счет чего мы
                                их получаем и что делать, чтобы удержать и
                                улучшить результаты.
                            </p>
                        </div>
                    ),
                    title: 'За счёт каких показателей увеличился оборот Бренда 2?',
                    hint: 'Средний чек у Бренда 2 сократился, а вот количество чеков выросло на 26,7%, увеличились продажи в натуральном выражении (шт и кг) на 12,1 и 12,3%, а рост средней цены позволил достичь увеличения оборота практически на четверть, на 24,7%',
                    answers: [
                        {
                            title: 'Продажи в натуральном выражении',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Цена',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Трафик',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Средний чек, шт',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-1-02',
                    type: 'multiple',
                    content: (
                        <div>
                            <p>
                                Отлично, мы знаем, что драйвером продаж в
                                натуральном выражении является рост трафика. Но
                                почему? Ответ на этот вопрос можно поискать в
                                данных по картам лояльности.
                            </p>
                        </div>
                    ),
                    title: 'Какие метрики помогают установить причины увеличения трафика?',
                    hint: 'Трафик, то есть количество чеков зависит от количества покупателей и частоты покупки',
                    answers: [
                        {
                            title: 'Частота покупки',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Ассортимент',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Количество покупателей',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Средняя цена',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-1-03',
                    type: 'multiple',
                    content: (
                        <div>
                            <p>
                                Исходя из данных карт лояльности трафик Бренда 2
                                вырос, в основном, из-за увеличения количества
                                покупателей (+24%), а частота покупки Бренда 2
                                выросла незначительно (менее, чем +2%).
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case1/case1_2.png'}
                                largeImage={'/images/case1/case1_2.png'}
                            />
                        </div>
                    ),
                    title: 'Какие факторы напрямую влияют на увеличение количества покупателей?',
                    hint: 'К факторам, которые непосредственно влияют на количество покупателей можно отнести, во-первых, рост представленности: чем больше магазинов, тем больше людей видят продукт, а во-вторых, привлекательную цену, которая способна сделать товар более доступным для бОльшего числа покупателей.',
                    answers: [
                        {
                            title: 'Увеличение количества магазинов',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Увеличение размера упаковки',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Снижение ценового индекса',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Изменение вкуса продукта',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-1-04',
                    type: 'multiple',
                    content: (
                        <div>
                            <p>
                                В этом примере Бренду2 удалось расширить
                                дистрибуцию (количество магазинов, в которых
                                продавался Бренд 2, выросло на 9%), а также
                                понизить свой ценовой индекс, благодаря
                                умеренному росту цены (ниже, чем в целом по
                                категории)
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case1/case1_3.png'}
                                largeImage={'/images/case1/case1_3.png'}
                            />
                        </div>
                    ),
                    title: 'Что вы порекомендуете сделать, чтобы поддержать достигнутый рост?',
                    hint: 'Благодаря аналитике мы определили факторы, которые обеспечили нашему продукту рост: ими оказались значительное расширение аудитории продукта в комбинации с умеренным увеличением средней цены. Теперь мы можем подготовить план по поддержанию достигнутых результатов: то есть разработать действия направленные на дальнейшее расширение базы покупателей (например, расширение дистрибуции, вывод новых СКЮ в пока ещё не охваченных сегментах категории, обновлённая информационная рекламная кампания, направленная на привлечение новых покупателей и т.д.) а также, удерживать текущие цены или же, если рынок и ценовая эластичность нашего продукта позволяет, даже продолжить постепенное повышение цены. Такой план позволит нам закрепить достигнутых успех и усилить его.',
                    answers: [
                        {
                            title: 'Ввести новые СКЮ в пока ещё не охваченных, перспективных сегментах категории',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Провести рекламную кампанию, направленную на привлечение новых покупателей',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Удерживать текущие цены или продолжить постепенное повышение цен',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Привозить больше СКЮ в магазины',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
            ],
        },
        {
            content: {
                index: '02',
                title: 'Снижение промо активности привело к снижению доли на фоне роста промо объемов конкурента',
                description:
                    'В этом кейсе посмотрим, как снижение промо-активности привело к снижению доли',
            },
            questions: [
                {
                    id: 'case-2-01',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                У Бренда 3 ситуация не очень. При том, что сам
                                продукт входит в Топ-3, его оборот не растёт,
                                при этом растет оборот ключевого конкурента.
                                Куда бежать в такой ситуации? Конечно, в
                                аналитику!
                            </p>
                            <p>Перед нами динамика основных чековых метрик.</p>
                            <ImageWithLightbox
                                smallImage={'/images/case2/case2_1.png'}
                                largeImage={'/images/case2/case2_1.png'}
                            />
                        </div>
                    ),
                    title: 'Исходя из таблицы с данными, что можно сказать про развитие категории?',
                    hint: 'Несмотря на то, что категория действительно растет в денежном выражении, при анализе мы не должны игнорировать факт снижения продаж в натуральном выражении.',
                    answers: [
                        {
                            title: 'Категория растет в денежном выражении благодаря росту среднего чека в рублях',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Категория сокращается в натуральных объемах под воздействием снижения среднего размера покупки',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'В категории растет трафик благодаря росту средней цены',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Пенетрация категории не изменилась',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-2-02',
                    type: 'multiple',
                    content: (
                        <div>
                            <p>
                                Совершенно верно, мы видим, что оборот категории
                                упал в продажах в натуральном выражении на
                                -4,6%/-6,0% в шт/кг на фоне двузначного снижения
                                среднего чека в шт/кг. При этом для категории
                                вырос трафик.
                            </p>
                        </div>
                    ),
                    title: 'Какие покупательские метрики влияют на увеличение трафика?',
                    hint: 'На рост трафика или количества чеков влияет увеличение количества покупателей, а также частота покупки',
                    answers: [
                        {
                            title: 'Количество покупателей',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Средний чек',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Частота покупки',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Пол и возраст покупателей',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-2-03',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Теперь посмотрим на показатели карт лояльности в
                                отношении анализируемого Бренда 3:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case2/case2_2.png'}
                                largeImage={'/images/case2/case2_2.png'}
                            />
                            <p>
                                Продажи Бренда 3 снизились за счёт всех
                                покупательских факторов, однако главным
                                драйвером является отрицательная динамика
                                количества покупателей, которая идет вразрез с
                                трендом категории.
                            </p>
                        </div>
                    ),
                    title: 'Какие на этом этапе можно выдвинуть гипотезы?',
                    hint: 'В первую очередь обращаем внимание на те показатели, которые для категории демонстрируют рост, а для нашего продукта — падение. В случае Бренда 3 – это количество покупателей',
                    answers: [
                        {
                            title: 'Темпы роста цены в категории незначительно выше и не влияют на положение Бренда 3',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Бренд 3 теряет покупателей, под воздействием роста цены, который значительно опережает рост средней цены в категории',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Рост денежных продаж категории обусловлен ростом трафика и цены',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Динамика среднего чека Бренда 3 в натуральных величинах значительно хуже, чем в среднем по категории',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-2-04',
                    type: 'multiple',
                    content: (
                        <div>
                            <p>
                                То, что падение продаж Бренда 3 сопровождалось
                                значительным ростом средней цены, которое
                                серьёзно опережало рост средней цены в
                                категории, а также практические вдвое рост
                                средней цены Бренда 2 (ключевого конкурента,
                                который смог вырастить свою долю), подводит нас
                                к выводу о том, что падение Бренда 3 связано с
                                сокращением промоактивности. Давайте попробуем
                                проверить эту гипотезу.
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case2/case2_3.png'}
                                largeImage={'/images/case2/case2_3.png'}
                            />
                            <p>
                                Действительно, мы видим, что промопродажи Бренда
                                3 значительно упали, а их доля сократилась с 82%
                                до 67%.
                            </p>
                            <p>
                                Давайте посмотрим, что происходило с долей промо
                                у Бренда 2.
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case2/case2_4.png'}
                                largeImage={'/images/case2/case2_4.png'}
                            />
                            <p>
                                Доля промо Бренда 2 практически не изменилась,
                                при этом мы наблюдаем как значительный рост
                                промопродаж, так и регулярных продаж.
                            </p>
                        </div>
                    ),
                    title: 'Что вы порекомендуете сделать на основе анализа данных?',
                    hint: 'На этапе оценки текущей ситуации аналитика позволяет определить, что мы проигрываем конкурентную борьбу, несмотря на то что сама категория растёт. На следующей стадии анализа мы сосредоточились на изучении причин снижения спроса на наш продукт и факторов, способствующих успеху продукции конкурента. Мы определили, что основная причина неудачи состоит в том, что мы значительно снизили промо-активность, в то время как конкурент сохранил её на прежнем уровне. Имея эту информацию на этапе планирования и постановки целей нам следует тщательно переработать план по проведению промо активностей, а также рассмотреть возможность снижения регулярной полочной цены. Эти шаги помогут обеспечить нам лучшую конкурентоспособность по отношению к ключевому сопернику и изменить ситуацию в лучшую сторону.',
                    answers: [
                        {
                            title: 'Расширить ассортимент',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Пересмотреть план промо-активностей',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Заменить товар на аналогичный, но с более низкой ценой',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Рассмотреть вопрос о временном снижении цены',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
            ],
        },
        {
            content: {
                index: '03',
                title: 'Бренд добился существенного снижения доли промо при этом удалось сохранить объем продаж за счет значительного развития дистрибуции',
                description:
                    'В этом кейсе мы узнаем, как Бренд 1 за счет работы с ассортиментом сумел снизить % промо',
            },
            questions: [
                {
                    id: 'case-3-01',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Оборот категории растёт, однако для Бренда 1,
                                который входит в Топ-3, темпы роста отстают.
                                Хорошо, что доля ключевого конкурента не растёт.
                            </p>
                            <p>
                                Но давайте разбираться по порядку, внимание на
                                ключевые метрики:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case3/case3_1.png'}
                                largeImage={'/images/case3/case3_1.png'}
                            />
                        </div>
                    ),
                    title: 'Что можно сказать про динамику продаж Бренда 1?',
                    hint: 'В данном случае у Бренда 1 мы наблюдаем замедление темпов роста продаж и количества транзакций по сравнению с категорией, а также чуть более выраженное уменьшение объемов продаж в килограммах',
                    answers: [
                        {
                            title: 'Бренд 1 вырос в продажах в рублях, но намного скромнее категории (всего +2,4%)',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Продажи Бренда 1 сокращаются в натуральном выражении (шт, кг)',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Трафик Бренда 1 растет, но уступает динамике категории, что приводит к падению пенетрации в чеки',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Все утверждения верны',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-3-02',
                    type: 'single',
                    content: (
                        <div>
                            А теперь давайте посмотрим на продажи, как на
                            произведение количества чеков на средний чек.
                        </div>
                    ),
                    title: 'Как вы думаете, в чем причина снижения продаж в натуральном выражении?',
                    hint: 'Несмотря на отставание от категории количество чеков Бренда 1 в абсолютных значениях выросло',
                    answers: [
                        {
                            title: 'Уменьшение среднего размера чека',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Уменьшение трафика',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Увеличение средней цены',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-3-03',
                    type: 'multiple',
                    content: (
                        <div>
                            <p>
                                Причина снижения продаж в натуральном выражении
                                — уменьшение среднего размера чека (и в шт, и в
                                кг). Трафик же немного подрос (количество чеков
                                увеличилось на +5%).
                            </p>
                            <p>
                                Нам нужно понять, что же могло повлиять на
                                падение среднего размера покупки.
                            </p>
                        </div>
                    ),
                    title: 'Какие из приведенных факторов могут оказывать влияние на сокращение среднего чека?',
                    hint: 'Увеличение промо-давления, как правило, является драйвером роста среднего чека, в то время как рост цен и сокращение представленности оказывают негативное влияние на данный параметр уравнения категории',
                    answers: [
                        {
                            title: 'Рост цен',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Увеличение промо-давления',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Сокращение товарного ассортимента бренда',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-3-04',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Давайте проверим, как обстояла ситуация с
                                ассортиментом у Бренда 1.
                            </p>
                            <p>
                                Для анализа ассортимента мы будем опираться на 3
                                ключевых показателя в динамике:
                            </p>
                            <p>
                                • Количество магазинов
                                <br />
                                • Количество товаров
                                <br />• Ассортимент (сумма уникальных комбинаций
                                товар+магазин)
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case3/case3_2.png'}
                                largeImage={'/images/case3/case3_2.png'}
                            />
                        </div>
                    ),
                    title: 'Что можно сказать про динамику ассортиментных метрик?',
                    hint: 'В рассматриваемом периоде ассортиментная матрица Бренда 1 пополнилась на 6 товаров, расширившись до 36 СКЮ по сравнению с 30 в предыдущем периоде. Кроме того, увеличилось количество магазинов, где представлен данный бренд',
                    answers: [
                        {
                            title: 'В анализируемом периоде у Бренда 1 продавалось меньше товаров, чем в предыдущем',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Количество магазинов, где продавались товары Бренда 1, не изменилось',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Бренду 1 удалось расширить общий ассортимент',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-3-05',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                С ассортиментом проблем не наблюдается:
                                действительно, Бренду 1 удалось расширить
                                ассортимент, заведя бОльшее количество СКЮ в
                                бОльшее количество магазинов.
                            </p>
                            <p>
                                Следующим шагом необходимо проанализировать
                                ценовые факторы.
                            </p>
                            <p>Посмотрим еще раз на основные метрики:</p>
                            <ImageWithLightbox
                                smallImage={'/images/case3/case3_3.png'}
                                largeImage={'/images/case3/case3_3.png'}
                            />
                            <p>
                                Также посмотрим, что происходило с долей промо:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case3/case3_4.png'}
                                largeImage={'/images/case3/case3_4.png'}
                            />
                        </div>
                    ),
                    title: 'С учетом приведенных выше показателей, как вы думаете, что послужило основной причиной снижения среднего размера покупки?',
                    hint: 'Поскольку промо является мощным инструментом, оказывающим влияние на рост среднего чека, резкое сокращение промо-активности приведет к обратному эффекту - уменьшению среднего чека',
                    answers: [
                        {
                            title: 'Рост средней цены за кг на 9,56%',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Значительное снижение доли промо в продажах Бренда 1',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Ценовые факторы не оказывают влияния на размер покупки',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-3-06',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Верно, основная причина сокращения размера
                                покупки – это значительное снижение доли промо в
                                продажах Бренда 1. Промо продажи снизились на
                                20% (доля промо уменьшилась с 76% до чуть более,
                                чем 60%). При этом, за счёт работы с
                                ассортиментом Бренду 1 удалось значительно
                                увеличить свои регулярные продажи, что в большой
                                степени смогло компенсировать снижение
                                промо-продаж.
                            </p>
                        </div>
                    ),
                    title: 'Что вы посоветует сделать, если наша цель — сокращение доли промо и увеличение доли регулярных продаж, чтобы снизить промо-зависимость?',
                    hint: 'Анализ показал, что значительное сокращение промо-активности привело к уменьшению доли нашего продукта. При разработке стратегии на следующем этапе мы должны определить наши стратегические цели. Если наша основная цель – увеличение доли рынка, то нам необходимо разработать план по увеличению промо-активности. В то же время, у нас может быть другой приоритет – сокращение доли промо и увеличение доли регулярных продаж с целью понизить промо-зависимость, снизить затраты на промо и увеличить маржинальность. Для второго сценария результаты, достигнутые в текущем периоде, можно оценить как успешные – мы снизили долю промо почти на 16 п.п., при этом практически не потеряв долю рынка (только 1 п.п.). Следовательно, в данном случае наш план должен быть сосредоточен на поддержании текущего уровня промо или его дальнейшем снижении и поддержке продаж через альтернативные инструменты (например, путём расширения представленности)',
                    answers: [
                        {
                            title: 'Поддерживать достигнутый уровень промо, но сократить уровень скидки',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Постепенно снижать уровень промо и поддерживать уровень продаж через другие инструменты, например, путём расширения дистрибуции',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Резко убрать все промо-активности и поднять регулярные цены на товар',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
            ],
        },
        {
            content: {
                index: '04',
                title: 'Ключевой конкурент оптимизировал ассортимент, что привело к значительному росту продаж и доли',
                description:
                    'В этом кейсе мы узнаем, почему Бренд 4 проигрывает конкурентную борьбу',
            },
            questions: [
                {
                    id: 'case-4-01',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Новый отчет по продажам выявил довольно
                                критическую ситуацию. Пока категория растет, наш
                                Бренд 4 топчется на месте, а его ключевой
                                конкурент показывает очень уверенный рост доли.
                                Как нам помочь Бренду 4 выбраться из этого
                                тяжелого положения и вновь завоевать сердца
                                покупателей? Давайте разбираться вместе!
                            </p>
                            <p>
                                На графике динамика доли нашего бренда и
                                конкурента:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case4/case4_1.png'}
                                largeImage={'/images/case4/case4_1.png'}
                            />
                            <p>
                                Настало время погрузиться в анализ, как всегда,
                                начинаем с ключевых метрик:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case4/case4_2.png'}
                                largeImage={'/images/case4/case4_2.png'}
                            />
                            <p>
                                В данном случае мы отмечаем негативную тенденцию
                                продаж как в денежном, так и в натуральном
                                выражении.
                            </p>
                        </div>
                    ),
                    title: 'Давайте посмотрим, за счёт каких показателей упал оборот Бренда 4 в натуральном выражении?',
                    hint: 'Продажи в натуральном выражении можно выразить через произведение общего количества чеков и среднего размера покупки, в рассматриваем периоде у Бренда 4 наблюдается снижение обоих показателей',
                    answers: [
                        {
                            title: 'Уменьшение трафика (количество чеков)',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Уменьшение среднего размера покупки',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Уменьшение трафика и среднего размера покупки',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-4-02',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Причиной сокращения продаж в натуральном
                                выражении стали уменьшение трафика (количество
                                чеков снизилось на -8%) и уменьшение среднего
                                размера покупки (-10% в шт. и -9% в кг).
                            </p>
                            <p>
                                Давайте попробуем сравнить динамику неценовых
                                показателей Бренда 4 с категорией, а также с
                                главным конкурентом Брендом 5:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case4/case4_3.png'}
                                largeImage={'/images/case4/case4_3.png'}
                            />
                            <p>
                                Нам нужно проанализировать точки роста
                                конкурента в категории, чтобы построить план по
                                изменению ситуации в лучшую сторону.
                            </p>
                        </div>
                    ),
                    title: 'Какие метрики демонстрируют у конкурента рост, а у нас падение?',
                    hint: 'Динамика цены и среднего чека у обоих брендов однонаправленная. Правильный ответ – количество чеков',
                    answers: [
                        {
                            title: 'Средний чек в рублях',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Количество чеков',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Цена за штуку',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-4-03',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Средний размер покупки в той или иной степени
                                снизился у обоих рассматриваемых брендов и в
                                категории в целом. При этом выросло количество
                                чеков в категории (+8%) а также у Бренда 5
                                (более чем в полтора раза: +59%).
                            </p>
                            <p>
                                А еще можно обратить внимание, что темпы роста
                                объёмов Бренда 5 в натуральном выражении в шт. и
                                в кг значительно отличаются.
                            </p>
                        </div>
                    ),
                    title: 'Как выдумаете, на что указывает подобная разница в динамике натуральных продаж?',
                    hint: 'Мы отмечаем разницу в динамике метрик в штуках и кг во всех измерениях: темпы роста продаж в шт существенно опережают продажи в шт (52% против 32%), цена за кг увеличилась на 17%, в то время как цена за шт выросла всего на 2%. Что касается динамики среднего чека, здесь мы видим практически четырехкратную разницу: снижение на 17% в кг против 5% в шт.',
                    answers: [
                        {
                            title: 'Дело в цене: средняя цена за кг существенно выросла, а средняя цена за штукупрактически не изменилась',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Изменился ассортимент, в следствие чего заметно сократился средний размер упаковки Бренда 5',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Произошла ошибка в подсчете объемов продаж в различных единицах измерения',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-4-04',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Чтобы проверить эту гипотезу, давайте посмотрим,
                                что происходило с ассортиментом у нас и у Бренда
                                5.
                            </p>
                            <p>
                                Перед нами 3 ключевых ассортиментных показателя
                                в динамике:
                            </p>
                            <p>
                                • Количество магазинов
                                <br />
                                • Количество товаров
                                <br />• Ассортимент (сумма уникальных комбинаций
                                товар+магазин)
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case4/case4_4.png'}
                                largeImage={'/images/case4/case4_4.png'}
                            />
                        </div>
                    ),
                    title: 'Какие можно сделать выводы из анализа ассортиментных метрик Бренда 4 и Бренда 5?',
                    hint: 'Количество товаров в ассортиментной матрица обоих рассматриваемых брендов не увеличилось. Общее количество магазинов Бренда 4 даже немного больше, чем у конкурента, однако значения в целом очень близки.',
                    answers: [
                        {
                            title: 'Бренд 5 добился более эффективного распределения ассортимента по бОльшему количеству торговых точек',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'У Бренда 5 выросло количество товарных позиций в ассортиментной матрице',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Общая дистрибуция Бренда 5 значительно превосходит дистрибуцию Бренда 4',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-4-05',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Оба бренда оптимизировали ассортимент, Бренд 5
                                вывел 2 товара, Бренд 4 также сократил
                                количество СКЮ (вывел один продукт). При этом,
                                общий ассортимент Бренда 4 сократился, а Бренду
                                5 наоборот, удалось нарастить общую
                                представленность.
                            </p>
                        </div>
                    ),
                    title: 'Что вы посоветуете сделать Бренду 4, чтобы поднять продажи?',
                    hint: 'В данном примере благодаря аналитике нам удалось выявить, что основной причиной снижения продаж стало изменение представленности ассортимента в магазинах на фоне уменьшения количества продуктов в линейке. Теперь нам предстоит разработать план по увеличению представленности нашего ассортимента в торговых точках для повышения эффективности и достижения успеха, подобно тому, что сделал конкурент, Бренд 5.',
                    answers: [
                        {
                            title: 'Изменить упаковку продукта на более привлекательную и похожую на дизайн Бренда 5',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Разработать план по увеличению представленности нашего ассортимента в торговых точках, аналогичный плану Бренда 5',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Провести рекламную кампанию для усиления продаж Бренда 4',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
            ],
        },
        {
            content: {
                index: '05',
                title: 'Снижение промо активности на 20% привело к значительному уменьшению среднего чека и продаж',
                description:
                    'В этом кейсе мы узнаем, почему Бренд 6 проигрывает конкурентную борьбу',
            },
            questions: [
                {
                    id: 'case-5-01',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Оборот категории растёт, а наш Бренд 6 опустился
                                в топе и теперь занимает 7ю строчку.
                            </p>
                            <p>
                                Однако не все так плохо - у главного конкурента
                                дела тоже идут туговато. А значит пора взяться
                                за аналитику, выявить причины и разработать план
                                действий, чтобы вернуться в игру!
                            </p>
                            <p>
                                На графике динамика доли нашего бренда и
                                конкурента:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case5/case5_1.png'}
                                largeImage={'/images/case5/case5_1.png'}
                            />
                            <p>Перейдем к рассмотрению базовых метрик:</p>
                            <ImageWithLightbox
                                smallImage={'/images/case5/case5_2.png'}
                                largeImage={'/images/case5/case5_2.png'}
                            />
                        </div>
                    ),
                    title: 'В какой последовательности следует начинать анализировать динамику базовых показателей продаж?',
                    hint: 'В первую очередь обращаем внимание на общий тренд натуральных продаж и цены',
                    answers: [
                        {
                            title: 'Сначала определить как меняется пенетрация и средний чек',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Сначала определить какие метрики драйвят динамику продаж: ценовые или неценовые',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Сначала проанализировать динамику цены по себе и конкурентам',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-5-02',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Да, первым делом, давайте выясним, что
                                определило негативную динамику Бренда 6.
                            </p>
                            <p>
                                Средняя цена Бренда 6 увеличилась, а снижение
                                оборота было вызвано падением продаж в
                                натуральном выражении ( -12% в шт. и -15% в кг).
                            </p>
                            <p>
                                Посмотрим на неценовые факторы более детально.
                            </p>
                        </div>
                    ),
                    title: 'Как вы думаете, в чем причина снижения продаж в натуральном выражении?',
                    hint: 'Динамика продаж в натуральном выражении складывается из динамики количества чеков и среднего чека. У Бренда 2 сократился размер среднего чека.',
                    answers: [
                        {
                            title: 'Уменьшение среднего размера чека',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Уменьшение трафика',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Рост средней цены',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-5-03',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Трафик был стабильным (количество чеков
                                практически не изменилось), а вот средний размер
                                покупки Бренда 6 снизился существенно.
                            </p>
                        </div>
                    ),
                    title: 'Какие из приведенных факторов могут оказывать влияние на уменьшение среднего чека?',
                    hint: 'Оба фактора - промо и ассортимент могут повлиять на сокращение среднего чека бренда',
                    answers: [
                        {
                            title: 'Сокращение промо активности',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Изменение товарного ассортимента бренда',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Промо и изменение ассортимента',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-5-04',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Оказать ключевое влияние на изменение размера
                                покупки могли 2 фактора: промо и изменение
                                ассортимента. Давайте их проверим.
                            </p>
                            <p>
                                Сначала мы посмотрим, как обстояла ситуация с
                                ассортиментом у Бренда 6.
                            </p>
                            <p>
                                Перед нами 3 ключевых ассортиментных показателя
                                в динамике:
                            </p>
                            <p>
                                • Количество магазинов
                                <br />
                                • Количество товаров
                                <br />• Ассортимент (сумма уникальных комбинаций
                                товар+магазин)
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case5/case5_3.png'}
                                largeImage={'/images/case5/case5_3.png'}
                            />
                        </div>
                    ),
                    title: 'Какие можно сделать выводы из анализа ассортиментных метрик Бренда 6?',
                    hint: 'У Бренда 6 все три ассортиментных метрики: дистрибуция, количество товаров и совокупный ассортимент показывают рост',
                    answers: [
                        {
                            title: 'Бренд 6 расширил представленность в торговых точках сети при том, что количество товаров в ассортиментной матрице осталось прежним',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Бренд 6 добился значительного роста ассортимента, встав с бОльшим количеством СКЮ в бОльшем количестве торговых точек',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'У Бренда 6 выросло количество товарных позиций в ассортиментной матрице на фоне стабильного количества торговых точек',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-5-05',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Действительно, Бренд 6 значительно увеличил свой
                                ассортимент, значит причина снижения среднего
                                чека кроется в другом.
                            </p>
                            <p>
                                Чтобы все прояснить, нам предстоит провести
                                анализ промо-активности.
                            </p>
                            <p>Посмотрим на промо-метрики в динамике:</p>
                            <ImageWithLightbox
                                smallImage={'/images/case5/case5_4.png'}
                                largeImage={'/images/case5/case5_4.png'}
                            />
                        </div>
                    ),
                    title: 'Можно ли сказать, что именно фактор промо оказал влияние на падение продаж в рассматриваемом периоде?',
                    hint: 'Сравнивая динамику промо и регулярных продаж в постпериоде по сравнению с препериодом отчетливо видно сокращение промо части, и рост регулярных продаж (часть оранжевого столбца, не пересекающаяся с фиолетовой)',
                    answers: [
                        {
                            title: 'Да, промо-продажи сократились, в то время как регулярные, наоборот показывают рост',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Нет, поскольку сократились как промо, так и регулярные продажи',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Нельзя сделать однозначный вывод, так как на диаграмме нет информации об объеме регулярных продаж',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-5-06',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Бренд 6 значительно сократил долю промо продаж
                                (с 80% до 66%), продажи по промо в абсолюте
                                снизились на 20%.
                            </p>
                        </div>
                    ),
                    title: 'Что вы порекомендуете сделать Бренду 6, чтобы исправить ситуацию в будущем?',
                    hint: 'В этом примере мы смогли определить, что при весьма эффективном запуске большого количества новых СКЮ мы кардинально снизили промо активность. Именно снижение промо активности негативно сказалось на продажах нашего Бренда. При подготовке плана нам будет необходимо тщательно проработать календарь промо активностей на будущий период, с целью изменить динамику продаж с негативной на позитивную.',
                    answers: [
                        {
                            title: 'Увеличить количество новых СКЮ даже еще больше, чтобы компенсировать потери',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Изменить дизайн упаковки товара, чтобы привлечь новых потребителей',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Провести анализ эффективности промо-акций и поддерживать самые успешные инициативы',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
            ],
        },
        {
            content: {
                index: '06',
                title: 'Благодаря успешному запуску товаров большего размера удалось достичь увеличения пенетрации и роста в руб и кг',
                description:
                    'В этом кейсе мы узнаем, почему Бренд 6 проигрывает конкурентную борьбу',
            },
            questions: [
                {
                    id: 'case-6-01',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Дела у Бренда 1 идут отлично, его доля растёт,
                                однако оборот категории не растёт. Какие
                                действияследует предпринять, чтобы сохранить
                                достигнутый успех?
                            </p>
                            <p>Давайте разбираться вместе!</p>
                            <p>
                                Для начала посмотрим на основные метрики
                                уравнения категории:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case6/case6_1.png'}
                                largeImage={'/images/case6/case6_1.png'}
                            />
                        </div>
                    ),
                    title: 'Какую можно выявить особенность в динамике продаж бренда 1?',
                    hint: 'Все неценовые показатели Бренда1 растут, за исключением среднего чека и продаж в штуках.',
                    answers: [
                        {
                            title: 'Количество чеков выросло несущественно',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Неценовые метрики в шт сокращаются, а в кг растут',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Рост цены >14% спровоцировал снижение спроса и падение оборота в шт',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-6-02',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Действительно, нам стоит обратить внимание на
                                разнонаправленную динамику объёмов в натуральном
                                выражении: продажи в шт снизились, а вот продажи
                                в кг выросли. Это говорит о том, что вырос
                                средний размер упаковки (в среднем, одна единица
                                продукта Бренда 1, купленная в анализируемом
                                периоде весила больше, чем периодом ранее).
                            </p>
                            <p>
                                Что касается трафика, то хотя рост и составил
                                всего 1%, однако он ощутимо повлиял на
                                увеличение пенетрации в чеки категории (+11%).
                            </p>
                        </div>
                    ),
                    title: 'К какому источнику данных следует обратиться, чтобы определить за счёт чего вырос трафик?',
                    hint: 'Покупательская аналитика позволяет посмотреть на более глубокие причины изменения трафика',
                    answers: [
                        {
                            title: 'Отчеты торговых представителей',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Фокус-группы',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Аналитика карт лояльности',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-6-03',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                В нашем случае, продажи по картам лояльности
                                составляют более 80% от общих продаж Бренда 1,
                                поэтому мы справедливо можем использовать их для
                                ориентира.
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case6/case6_2.png'}
                                largeImage={'/images/case6/case6_2.png'}
                            />
                            <p>
                                Мы видим, что в рамках карт лояльности динамика
                                Бренда 1 ещё более выражена (рост продаж
                                составляет 19%, а не чуть больше 11%; а
                                количество чеков выросло на 10%, а не 1%)
                            </p>
                        </div>
                    ),
                    title: 'А теперь вернемся к трафику, какие 2 метрики влияют на количество чеков?',
                    hint: 'Количество чеков можно выразить как произведение количества покупателей и средней частоты покупок',
                    answers: [
                        {
                            title: 'Количество покупателей и Количество товаров, купленных покупателем, шт',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Количество покупателей и Частота покупки, раз в период',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Пенетрация в покупателей категории и Средний чек, шт',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-6-04',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                На уровне карт лояльности рост количества чеков
                                был обусловлен в первую очередь ростом
                                количества покупателей (+9%). Средняя частота
                                покупки выросла не так значительно (+1,3%).
                            </p>
                        </div>
                    ),
                    title: 'При анализе причин роста количества покупателей какой фактор требуется проверить в первую очередь?',
                    hint: 'При значительном увеличении числа покупателей, фактор дистрибуции может играть ключевую роль',
                    answers: [
                        {
                            title: 'Расширение представленности',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Изменение дизайна упаковки',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Совместная рекламная компания с известным блогером',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-6-05',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Совершенно верно, в первую очередь мы можем
                                проверить показатели представленности и
                                ассортимента.
                            </p>
                            <p>
                                Для анализа представленности мы будем опираться
                                на 3 ключевых показателя ассортимента в
                                динамике:
                            </p>
                            <p>
                                • Количество магазинов
                                <br />
                                • Количество товаров
                                <br />• Ассортимент (сумма уникальных комбинаций
                                товар+магазин)
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case6/case6_3.png'}
                                largeImage={'/images/case6/case6_3.png'}
                            />
                        </div>
                    ),
                    title: 'Что можно сказать про динамику ассортиментных метрик Бренда 1?',
                    hint: 'Все ассортиментные метрики Бренда 1 показывают положительную динамику',
                    answers: [
                        {
                            title: 'У Бренда 1 увеличилось только количество продаваемых товаров, влияние динамики количества магазинов несущественно',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'У Бренда 1 увеличилось только количество магазинов, влияние динамики количества товаров несущественно',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Бренду 1 удалось расширить общую представленность, как за счет роста количества магазинов, так и за счет количества товаров',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-6-06',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Вы правы, данные говорят нам о том, что рост
                                пенетрации Бренда 1 связан с успешным
                                расширением представленности (количество
                                магазинов, в которых продавался Бренд 1
                                увеличился более чем на 9%), а также со
                                значительным расширение ассортимента (в том
                                числе, предложением новых SKU большего размера).
                            </p>
                        </div>
                    ),
                    title: 'Что вы порекомендуете сделать, чтобы поддержать достигнутый рост?',
                    hint: 'Анализ данных позволил нам определить ключевые факторы, повлиявшие на рост нашего продукта: увеличение пенетрации за счёт успешного запуска новых СКЮ в комбинации с эффективным расширение дистрибуции как новых, так и существовавших СКЮ, а также умеренным ростом цены. Обладая этой информацией при разработке плана дальнейших действий, мы сможем предусмотреть сохранение новых цен в комбинации с действиями, направленными на дальнейший рост пенетрации (например, продолжать внедрять новые инновационные продукты), чтобы усилить достигнутый эффект.',
                    answers: [
                        {
                            title: 'Повысить цены на продукцию, чтобы создать образ высокого качества и престижности бренда',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Продолжать внедрять инновации, основываясь на анализе покупательских потребностей',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Сократить размер упаковки для повышения доходности на СКЮ',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
            ],
        },
        {
            content: {
                index: '07',
                title: 'Ключевой конкурент реализовал свое ценовое преимущество, что позволило ему нарастить дистрибуцию и переключить на себя покупателей',
                description:
                    'В этом кейсе мы узнаем, что предпринял конкурент и как нам скорректировать стратегию',
            },
            questions: [
                {
                    id: 'case-7-01',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Наш Бренд 2 оказался в непростой ситуации – мы
                                уступили лидерство нашему конкуренту, и это на
                                фоне того, что общий оборот категории не растёт.
                                Как это исправить?
                            </p>
                            <p>Давайте посмотрим на уравнение категории:</p>
                            <ImageWithLightbox
                                smallImage={'/images/case7/case7_1.png'}
                                largeImage={'/images/case7/case7_1.png'}
                            />
                            <p>
                                <b>Что мы видим?</b>
                            </p>
                            <ul>
                                <li>
                                    Оборот категории снизился чуть более, чем на
                                    5%.
                                </li>
                                <li>
                                    Продажи нашего Бренда 2 упали (-34,7%), а
                                    ключевому конкуренту удалось значительно
                                    увеличить продажи (+25,4%)
                                </li>
                                <li>
                                    Рост средней цены Бренда 2 сопоставим с
                                    категорией в целом, а у Бренда 1 темпы роста
                                    средней цены были намного ниже (+19,5% vs
                                    +8,6% за кг)
                                </li>
                            </ul>
                            <p>
                                В результате наша доля существенно сократилась,
                                и мы переместились с первой на вторую строчку:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case7/case7_2.png'}
                                largeImage={'/images/case7/case7_2.png'}
                            />
                        </div>
                    ),
                    title: 'Давайте разберемся, почему упали наши продажи в рублях?',
                    hint: 'У Бренда 2 все неценовые метрики демонстрируют снижение, однако снижение трафика является самым существенным (-41,8%), и оказывает ключевое влияние на продажи',
                    answers: [
                        {
                            title: 'Ключевой причиной стало сокращение среднего чека в шт',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Ключевой причиной стало снижение средней цены за шт',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Ключевой причиной сокращения продаж стал трафик',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-7-02',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Главным фактором снижения оборота в рублях стал
                                трафик: количество покупок уменьшилось на 42%,
                                но при этом средний чек увеличился из-за
                                повышения цены.
                            </p>
                            <p>
                                В натуральных величинах сокращение количества
                                чеков также является ключевым фактором,
                                поскольку уменьшение среднего чека в штуках и
                                килограммах менее выражено.
                            </p>
                            <p>
                                Чтобы выяснить, за счет чего снизился трафик, мы
                                можем более подробно рассмотреть неценовые
                                факторы рассчитанные по картам лояльности,
                                поскольку продажи по картам лояльности
                                составляют более 80% от всех продаж Бренда 2.
                            </p>
                            <p>
                                Перед нами метрики карт лояльности в отношении
                                анализируемого Бренда 2:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case7/case7_3.png'}
                                largeImage={'/images/case7/case7_3.png'}
                            />
                        </div>
                    ),
                    title: 'За счет каких неценовых факторов произошло падение трафика?',
                    hint: 'Трафик (количество чеков) упал, поскольку на 28% сократилось количество покупателей, а частота покупок почти на 10%',
                    answers: [
                        {
                            title: 'Уменьшение количества покупателей категории и повышение цены',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Сокращение количества покупателей бренда и снижение частоты покупки',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'В первую очередь за счет снижения частоты покупки',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-7-03',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Принимая во внимание снижение пенетрации в
                                покупателей, давайте проверим как обстояли дела
                                с ассортиментом и представленностью.
                            </p>
                            <p>
                                Перед нами 3 ключевых ассортиментных показателя
                                в динамике:
                            </p>
                            <p>
                                • Количество магазинов
                                <br />
                                • Количество товаров
                                <br />• Ассортимент (сумма уникальных комбинаций
                                товар+магазин)
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case7/case7_4.png'}
                                largeImage={'/images/case7/case7_4.png'}
                            />
                        </div>
                    ),
                    title: 'Какие можно сделать выводы из анализа ассортиментных метрик Бренда 2?',
                    hint: 'Диаграмма показывает, что у Бренда 2 сократилось количество товаров в ассортиментной матрице. В новом периоде 9 товаров перестали продаваться, и лишь треть из них была заменена новыми товарами.',
                    answers: [
                        {
                            title: 'Бренд 2 расширил представленность в торговых точках благодаря росту количества магазинов',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Бренду 2 удалось скомпенсировать сокращение количества продаваемых товаров за счет увеличения дистрибуции',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Общий ассортимент Бренда 2 сократился под воздействием сокращения товаров в ассортиментной матрице',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-7-04',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Правильный ответ в том, что у Бренда 2 несколько
                                снизилось общее количество СКЮ, и хотя общая
                                дистрибуция Бренда 2 немного увеличилась,
                                итоговая представленность ассортимента
                                ухудшилась.
                            </p>
                            <p>
                                Давайте теперь рассмотрим детально показатели
                                ключевого конкурента:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case7/case7_5.png'}
                                largeImage={'/images/case7/case7_5.png'}
                            />
                        </div>
                    ),
                    title: 'Что бы вы сказали про динамику покупательских метрик Бренда 1?',
                    hint: 'У нашего конкурента наблюдается увеличение трафика (+30%), вызванное как увеличением количества покупателей (+24%), так и увеличением частоты покупок (+5%). В то же время, средний чек в натуральных единицах немного снизился.',
                    answers: [
                        {
                            title: 'Рост Бренда 1 обусловлен ростом среднего потребления на покупателя',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Рост Бренда 1 обусловлен трафиком, увеличением количества покупателей и частоты покупки',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Все покупательские метрики Бренда 1 показывают рост',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-7-05',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Теперь посмотрим на динамику ассортимента Бренда
                                1:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case7/case7_6.png'}
                                largeImage={'/images/case7/case7_6.png'}
                            />
                        </div>
                    ),
                    title: 'Сравните ассортиментные метрики конкурента с динамикой нашего бренда:',
                    hint: 'Проведя анализ ассортимента конкурента, можно сделать вывод, что в новом периоде Бренд 1 значительно укрепил свои позиции, расширив свое присутствие в бОльшем количестве магазинов с бОльшим количеством СКЮ',
                    answers: [
                        {
                            title: 'У Бренда 1 увеличилось количество представленных СКЮ по сравнению с Брендом 2',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Дистрибуция Бренда 1 выше, чем у нашего Бренда 2',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Общая представленность ассортимента Бренда 1 значительно выросла',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Все вышеперечисленные утверждения верны',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-7-06',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Таким образом, Бренд 1 выигрывает конкурентную
                                борьбу из-за более эффективного управления
                                ассортиментом, а также удачной ценовой политики.
                            </p>
                        </div>
                    ),
                    title: 'Что вы порекомендуете сделать, чтобы изменить ситуацию с нашим брендом?',
                    hint: 'В этом примере мы смогли установить, что падение продаж нашего Бренда вызвано серьёзным проигрышем в конкурентной борьбе. Проведенный анализ позволил выявить, что успех конкурента обусловлен двумя ключевыми факторами: масштабным увеличением ассортимента и эффективным построением его дистрибуции, а также с тем, что конкурент удержал темпы роста цен на свои продукты значительно ниже средних темпов роста категории. Обладая этой информацией на этапе планирования и постановки целей мы можем скорректировать ценовую политику, а также пересмотреть ассортимент СКЮ нашего Бренда, чтобы избежать дальнейшего падения или даже развернуть тренд в сторону роста продаж нашего Бренда',
                    answers: [
                        {
                            title: 'Пересмотреть ассортиментную представленность и установить конкурентоспособные цены',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Переместить продукт в прикассовую зону',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Скопировать товары из ассортимента конкурента',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
            ],
        },
        {
            content: {
                index: '08',
                title: 'Неудачное ассортиментное решение – потеря покупателей в результате вывода СКЮ большого размера',
                description:
                    'В этом кейсе мы узнаем причины отрицательной динамики Бренда 8.',
            },
            questions: [
                {
                    id: 'case-8-01',
                    type: 'multiple',
                    content: (
                        <div>
                            <p>
                                Отчет с базовыми метриками по продажам и чекам
                                выявил не очень позитивную ситуацию для Бренда
                                8: продажи категории в рублях снижается на 1,3%,
                                а анализируемого бренда на 29%! Натуральные
                                продажи бренда также снижаются на 28% в штуках и
                                36% в объемах. Давайте изучим подробнее динамику
                                объёмов в натуральном выражении и разберемся
                                из-за чего сокращается объем продаж.
                            </p>
                            <p>
                                Предлагаем подробнее изучить динамику объёмов в
                                натуральном выражении:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case8/case8_1.png'}
                                largeImage={'/images/case8/case8_1.png'}
                            />
                        </div>
                    ),
                    title: 'Давайте посмотрим, за счёт каких показателей упал оборот Бренда 4 в натуральном выражении?',
                    hint: 'Причиной снижения продаж стал трафик (количество чеков упало на 28%), а также средний размер покупки (средний чек в кг Бренда 8 сократился на 11%)',
                    answers: [
                        {
                            title: 'Уменьшение трафика (количество чеков)',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Уменьшение среднего размера покупки',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Изменение средней цены в рублях',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-8-02',
                    type: 'multiple',
                    content: (
                        <div>
                            <p>
                                Причиной снижения продаж стал именно трафик,
                                количество чеков упало на -28%, а средний размер
                                покупки Бренда 8 практически не изменился как в
                                штуках, так и в объемах.
                            </p>
                            <p>
                                Чтобы подробнее разобраться с причиной падения
                                трафика на 28% мы можем обратиться к данным карт
                                лояльности, которые составляют порядка 80% общих
                                продаж Бренда 8, а значит являются
                                репрезентативным источником данных:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case8/case8_2.png'}
                                largeImage={'/images/case8/case8_2.png'}
                            />
                            <ImageWithLightbox
                                smallImage={'/images/case8/case8_3.png'}
                                largeImage={'/images/case8/case8_3.png'}
                            />
                        </div>
                    ),
                    title: 'Какие метрики по данным карт лояльности повлияли на падение количества чеков?',
                    hint: 'Несмотря на общий спад показателей покупательского поведения, включая и средние расходы покупателя, за снижение трафика отвечают количество покупателей и частота покупки',
                    answers: [
                        {
                            title: 'Покупатели группы товаров (Бренда 8)',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Расходы покупателя',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Частота покупки',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-8-03',
                    type: 'multiple',
                    content: (
                        <div>
                            <p>
                                Судя по данным карт лояльности, падение трафика
                                произошло в первую очередь из-за уменьшения
                                количества покупателей (-19%). Частота покупки
                                также снизилась (-7%), что тоже повлияло на
                                падение количества чеков.
                            </p>
                            <p>
                                Предлагаем «копнуть глубже» и рассмотреть
                                изменения в ассортименте Бренда 8.
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case8/case8_4.png'}
                                largeImage={'/images/case8/case8_4.png'}
                            />
                            <p>
                                Мы видим, что общее количество SKU выросло: одно
                                SKU было выведено, а заведено четыре новых.
                                Дистрибуция Бренда 8 также немного увеличилась.
                            </p>
                        </div>
                    ),
                    title: 'Какие выводы мы можем сделать исходя из показателей продаж бренда и динамики ассортимента:',
                    hint: 'Получается, что хотя Бренд 8 увеличил свою представленность, а также расширил общее количество СКЮ в своем портфеле, управление ассортиментом оказалось не очень удачным. То единственное СКЮ в упаковке большого размера, которое было выведено (именно из-за него мы выявили заметное снижение среднего размера упаковки в самом начале анализа) оказалось очень важным для покупателей марки. Результатом этого делистинга стало падение продаж и доли Бренда 8.',
                    answers: [
                        {
                            title: 'Бренд 8 увеличил свою представленность, а также расширил общее количество СКЮ в своем портфеле',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Выведенный товар был в упаковке бОльшего размера, это отчасти привело к заметному снижению продаж в объеме',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Управление ассортиментом оказалось не очень удачным, так как его результатом стало падение продаж и доли бренда',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-8-04',
                    type: 'multiple',
                    content: (
                        <div>
                            <p>
                                Давайте проверим, не привели ли эти
                                ассортиментные изменения к переключениям
                                покупателей Бренда 8 на другие продукты в
                                категории.
                            </p>
                            <p>
                                Для этого проанализируем снижение продаж Бренда
                                8 в разрезе потребления разных групп
                                покупателей.
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case8/case8_5.png'}
                                largeImage={'/images/case8/case8_5.png'}
                            />
                            <p>
                                Мы видим, что снижение потребления постоянных
                                покупателей бренда – это фактор который внёс
                                самый большой вклад в падение продаж в кг. В то
                                же время, на потери за счёт конкурентных
                                переключений также пришлась значительная часть
                                падения оборота в 9%.
                            </p>
                        </div>
                    ),
                    title: 'Что вы посоветуете сделать Бренду 8, чтобы изменить текущее положение?',
                    hint: 'Здесь аналитика позволила нам выяснить, что драматическое падение продаж нашего бренда стало следствием не очень удачных решений в рамках ассортиментной политики. Несмотря на то, что мы расширили общий ассортимент СКЮ нашего бренда и при этом смогли расширить общую представленность ассортимента в торговых точках, вывод из продажи одного единственного СКЮ большого размера оказал крайне негативное влияние на продажи. Зная это на этапе планирования дальнейших действий и постановки целей, мы можем ещё раз пересмотреть ассортимент Бренда и подготовить к запуску новые СКЮ большого размера, либо вернуть в продажу СКЮ, которое мы решили вывести ранее.',
                    answers: [
                        {
                            title: 'Пересмотреть ассортимент Бренда и подготовить к запуску новые СКЮ большого размера, либо вернуть в продажу выведенный товар',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Нарастить дистрибуцию с текущим ассортиментом',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Проработать календарь промо и скорректировать цены на ассортимент',
                            isValid: false,
                            hint: '',
                        },
                    ],
                },
            ],
        },
        {
            content: {
                index: '09',
                title: 'Оценить успешность нового запуска',
                description:
                    'В этом кейсе мы узнаем, почему Бренд 6 проигрывает конкурентную борьбу',
            },
            questions: [
                {
                    id: 'case-9-01',
                    type: 'multiple',
                    content: (
                        <div>
                            <p>
                                Бренд 9 – новый бренд в категории, который
                                запустился шестнадцать недель назад в сети
                                Пятерочка. Бренд включает 3 СКЮ, которые
                                запустились в продажу одновременно.
                            </p>
                            <p>
                                Давайте рассмотрим динамику продаж всего бренда
                                в целом, а также отдельно каждого из трёх СКЮ:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case9/case9_1.png'}
                                largeImage={'/images/case9/case9_1.png'}
                            />
                        </div>
                    ),
                    title: 'Что нам показывает график продаж нового бренда и его товаров?',
                    hint: 'Мы видим, что с первой по десятую недели продажи запуска постоянно росли, на одиннадцатой неделе произошло падение продаж ниже уровня девятой недели. На двенадцатой неделе произошло восстановление: продажи снова увеличились и достигли своего максимума за наблюдаемый период. Начиная с тринадцатой недели по шестнадцатую продажи прекратили рост и демонстрируют небольшое снижение. Из трёх СКЮ Бренда 9 самые высокие продажи продемонстрировало СКЮ 3. Продажи СКЮ 2 совсем немного уступают СКЮ 3, а уровень продаж СКЮ 1 значительно ниже.',
                    answers: [
                        {
                            title: 'Продажи линейки показывают стабильный рост',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Пик продаж был на 12-й неделе',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'СКЮ 3 – лидер по объемам продаж',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-9-02',
                    type: 'multiple',
                    content: (
                        <div>
                            <p>
                                Действительно, мы видим рост продаж нового
                                бренда и всех его товаров до 10-й недели, а в
                                следующую неделю показали резкое падение, ниже
                                уровня девятой недели. Далее РТО сильно
                                увеличился и достиг своего пика в анализируемом
                                нами периоде.
                            </p>
                            <p>
                                Разложим бренд на товары: в линейке Бренда 9
                                самые высокие продажи продемонстрировало СКЮ 3.
                                Продажи СКЮ 2 совсем немного уступают лидеру, а
                                уровень продаж СКЮ 1 значительно ниже.
                            </p>
                            <p>
                                Анализировать динамику продаж новых товаров
                                необходимо с учетом его дистрибуции, как мы
                                знаем, это может быть одним из факторов
                                сокращения или резкого скачка продаж.
                            </p>
                            <p>
                                Давайте посмотрим на динамику количества
                                магазинов, в которых были продажи новых товаров:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case9/case9_2.png'}
                                largeImage={'/images/case9/case9_2.png'}
                            />
                            <p>
                                График количества магазинов объясняет нам
                                отмеченное ранее падение продаж на одиннадцатой
                                неделе. На этой неделе мы видим снижение
                                количества магазинов у всех трёх СКЮ.
                            </p>
                        </div>
                    ),
                    title: 'Какие дополнительные выводы можно сформулировать из динамики этого показателя?',
                    hint: 'Мы видим, что все три продукта отличаются по уровню дистрибуции: СКЮ 1 обладает самой низкой дистрибуцией на протяжении всего рассматриваемого периода. СКЮ 2 и СКЮ 3 были представлены в равном количестве торговых точек до третьей недели включительно, а начиная с четвёртой недели СКЮ 3 начинает заметно опережать СКЮ 2. Начиная с двенадцатой недели продаж количество магазинов стабилизируется и продолжает умеренный рост',
                    answers: [
                        {
                            title: 'Три товара новинки имели разную представленность в торговых точках',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'СКЮ 2 было представлено в большем количестве магазинов, чем остальные товары новинки',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Дистрибуция новинки развивается и показывает стабильный рост',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-9-03',
                    type: 'multiple',
                    content: (
                        <div>
                            <p>
                                Добавим небольшую подсказку в наш анализ – если
                                посмотреть на оба графика: продаж и дистрибуции,
                                то можно сделать еще одно наблюдение – разница в
                                дистрибуции между СКЮ 3 и СКЮ 2 ощутима, а
                                отставание продаж СКЮ 2 от СКЮ 3 не так
                                значительно. Для определения лучшего товара из
                                всей линейки по показателю продаж советуем
                                обратиться к метрике продаж на магазин:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case9/case9_3.png'}
                                largeImage={'/images/case9/case9_3.png'}
                            />
                            <p>
                                Здесь мы видим, что по показателю «уходимости»
                                из точки СКЮ 2 опережает СКЮ 3. Это является
                                хорошим сигналом для развития дистрибуции СКЮ 2,
                                так как на протяжении всего периода оно было
                                лидером по показателю продаж на магазин.
                            </p>
                            <p>
                                С основными метриками разобрались, теперь
                                предлагаем взглянуть на влияние запуска новинки
                                на категорию и другие бренды:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case9/case9_4.png'}
                                largeImage={'/images/case9/case9_4.png'}
                            />
                        </div>
                    ),
                    title: 'Как запуск новинки повлиял на динамику категории?',
                    hint: 'Оборот Бренда 9 за шестнадцать недель продаж составил более 105 миллионов рублей. При этом, треть продаж (34%) пришлись на покупателей, увеличивших потребление категории за счёт покупки Бренда 9, ещё почти треть продаж (32%) это продажи, которые сгенерировали новые для категории покупатели. Только оставшиеся 34% оборота Бренда 9 – это продажи возникшие за счёт переключения с других продуктов категории.',
                    answers: [
                        {
                            title: 'Новые покупатели в категории принесли новинке треть продаж',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Переключения с других брендов категории заняли наибольшую долю в общем объеме продаж',
                            isValid: true,
                            hint: '',
                        },
                        {
                            title: 'Более половины объема продаж новинки внесли инкрементальный вклад в динамику категории',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
                {
                    id: 'case-9-04',
                    type: 'single',
                    content: (
                        <div>
                            <p>
                                Отлично! На финальном этапе предлагаем
                                проанализировать, как запуск Бренда 9 повлиял на
                                покупателей категории и сети:
                            </p>
                            <ImageWithLightbox
                                smallImage={'/images/case9/case9_5.png'}
                                largeImage={'/images/case9/case9_5.png'}
                            />
                            <p>
                                За анализируемый период бренд привлек около
                                миллиона покупателей, большинство из них (65%),
                                ранее приобретали категорию, а с появлением
                                новинки увеличили потребление или переключились
                                на нее с других брендов. Откуда же пришли в
                                новинку остальные 35% покупателей? Около
                                четверти покупателей с появлением нового Бренда
                                9 на полке стали приобретать категорию, к
                                которой относится новинка.
                            </p>
                            <p>
                                Оставшиеся – новые покупатели как для новинки,
                                так и для всей торговой сети.
                            </p>
                        </div>
                    ),
                    title: 'На этом этапе наш увлекательный путь оценки новинки подошел к концу, осталось услышать Ваш вердикт – какие выводы применимы к анализу этого запуска?',
                    hint: 'В этом примере аналитика позволила нам, практически в режиме реального времени отслеживать динамику ключевых метрик запуска. В данном случае запуск прошёл достаточно успешно, но, если бы в какой-то момент мы бы обнаружили, что рост какого-то из показателей прекратился, мы сразу смогли бы углубиться в причины проблемы и принять оперативные тактические решения по исправлению ситуации.',
                    answers: [
                        {
                            title: 'Запуск Бренда 9 проходит достаточно успешно',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Отслеживание показателей в реальном времени позволяет оперативно влиять на продажи',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Для корректных выводов по эффективности важно также оценивать каннибализацию категории',
                            isValid: false,
                            hint: '',
                        },
                        {
                            title: 'Все вышеперечисленные утверждения верны',
                            isValid: true,
                            hint: '',
                        },
                    ],
                },
            ],
        },
    ],
    lessons: [
        {
            content: {
                index: '01',
                title: 'Важность аналитики данных',
                videoId: '17jLfZTyjCky8SBHg49nCp',
            },
            questions: [
                {
                    id: 'lesson-1-01',
                    type: 'single',
                    content: '',
                    title: 'Что позволяет эффективно управлять продажами?',
                    answers: [
                        {
                            title: 'Обширная линейка товаров',
                            isValid: false,
                            hint: 'Обширная линейка товаров влияет на продажи, но не управляет ими.',
                        },
                        {
                            title: 'Актуальные и достоверные данные',
                            isValid: true,
                            hint: 'Действительно, управлять продажами помогают именно данные.',
                        },
                        {
                            title: 'Большая команда специалистов разного профиля',
                            isValid: false,
                            hint: 'Хорошая команда, несомненно, важна, но этого недостаточно для эффективного управления продажами.',
                        },
                    ],
                },
                {
                    id: 'lesson-1-02',
                    type: 'single',
                    content: '',
                    title: 'Какой источник данных самый надежный?',
                    answers: [
                        {
                            title: 'Предположения маркетолога',
                            isValid: false,
                            hint: 'Это уважаемый источник данных, но его знания не покрывают все нужды для принятия эффективных решений.',
                        },
                        {
                            title: 'Интервью с директором магазина',
                            isValid: false,
                            hint: 'Это уважаемый, но субъективный источник данных, и картина реально происходящего в магазине может быть неполной.',
                        },
                        {
                            title: 'Чеки с информацией о покупках',
                            isValid: true,
                            hint: 'Действительно, это верный и объективный источник данных.',
                        },
                    ],
                },
                {
                    id: 'lesson-1-03',
                    type: 'single',
                    content: '',
                    title: 'Какие данные может показать Dialog.X5?',
                    answers: [
                        {
                            title: 'Анализ категории и конкурентов',
                            isValid: false,
                            hint: 'Dialog.X5 может проанализировать категории и конкурентов, но у него есть и другие возможности.',
                        },
                        {
                            title: 'Профиль клиента',
                            isValid: false,
                            hint: 'Dialog.X5 может показать профиль клиента, но у него есть и другие возможности.',
                        },
                        {
                            title: 'Изменение предпочтений покупателей',
                            isValid: false,
                            hint: 'Dialog.X5 может рассказать об изменении предпочтений покупателей, но у него есть и другие возможности.',
                        },
                        {
                            title: 'Всё вышеперечисленное',
                            isValid: true,
                            hint: 'Действительно, всё это Dialog.X5 показывает и анализирует. Отлично, не правда ли?',
                        },
                    ],
                },
                {
                    id: 'lesson-1-04',
                    type: 'single',
                    content: '',
                    title: 'Какой первый шаг для улучшения показателей продаж?',
                    answers: [
                        {
                            title: 'Узнать, что происходит с категорией, продажами продукта и долей рынка',
                            isValid: true,
                            hint: 'Действительно, начинать лучше с анализа актуальной ситуации.',
                        },
                        {
                            title: 'Съездить в магазин на полевой визит',
                            isValid: false,
                            hint: 'Иногда такая поездка может быть полезной, но она не может быть первым шагом для улучшения продаж.',
                        },
                        {
                            title: 'Изменить дизайн упаковки',
                            isValid: false,
                            hint: 'Иногда это решение может повлиять на продажи, но оно не может быть первым шагом.',
                        },
                    ],
                },
            ],
        },
        {
            content: {
                index: '02',
                title: 'Ключевые параметры аналитики',
                videoId: 'amEUqfNTunR9aKSsERAdWd',
            },
            questions: [
                {
                    id: 'lesson-2-01',
                    type: 'single',
                    content: '',
                    title: 'Что такое уравнение категории?',
                    answers: [
                        {
                            title: 'Уравнение, используемое для создания категорий в базе данных',
                            isValid: false,
                            hint: 'Уравнение категорий связано не с созданием категории, а с расчетами внутри одной категории.',
                        },
                        {
                            title: 'Математическое выражение, показывающее влияние различных факторов на изменение объема продаж',
                            isValid: true,
                            hint: 'Действительно, уравнение категории показывает объёмы продажи продукта в денежном выражении через количество покупателей, среднюю частоту покупки и размер покупки, а также среднюю цену единицы продукта.',
                        },
                        {
                            title: 'Функция для определения признаков сегментации',
                            isValid: false,
                            hint: 'Уравнение категории не определяет признаки сегментации, а связано с расчетами внутри категории.',
                        },
                    ],
                },
                {
                    id: 'lesson-2-02',
                    type: 'single',
                    content: '',
                    title: 'Из каких базовых параметров состоит первая стадия анализа?',
                    answers: [
                        {
                            title: 'Конкурентная позиция целевого продукта',
                            isValid: false,
                            hint: 'В первую стадию анализа входит не только этот параметр.',
                        },
                        {
                            title: 'Динамика категории',
                            isValid: false,
                            hint: 'В первую стадию анализа входит не только этот параметр.',
                        },
                        {
                            title: 'Динамика доли целевого продукта',
                            isValid: false,
                            hint: 'В первую стадию анализа входит не только этот параметр.',
                        },
                        {
                            title: 'Динамика ключевого конкурента',
                            isValid: false,
                            hint: 'В первую стадию анализа входит не только этот параметр.',
                        },
                        {
                            title: 'Все вышеперечисленное',
                            isValid: true,
                            hint: 'Действительно, в первую стадию входят все указанные параметры. Именно эти данные позволяют нам строить план действий.',
                        },
                    ],
                },
                {
                    id: 'lesson-2-03',
                    type: 'single',
                    content: '',
                    title: 'Что нужно учитывать при анализе?',
                    answers: [
                        {
                            title: 'Время выполнения анализа',
                            isValid: false,
                            hint: 'Время выполнения анализа не так важно.',
                        },
                        {
                            title: 'Сезонность и географию продаж',
                            isValid: false,
                            hint: 'Действительно, показатели продаж могут меняться в зависимости от сезона или региона. Например, для продукта, который представлен в конкретных регионах, будет корректно рассчитывать показатели для категории и конкурентов только из этих регионов.',
                        },
                        {
                            title: 'Экспертное мнение аналитика',
                            isValid: true,
                            hint: 'Мнение аналитика важно, но есть параметры более убедительные.',
                        },
                    ],
                },
                {
                    id: 'lesson-2-04',
                    type: 'single',
                    content: '',
                    title: 'Зачем нужно агрегировать и объединять продукты в группы?',
                    answers: [
                        {
                            title: 'Для оптимизации логистических процессов',
                            isValid: false,
                            hint: 'Сейчас нас интересует аналитика, а не логистика.',
                        },
                        {
                            title: 'Для улучшения клиентского сервиса и ассортимента',
                            isValid: false,
                            hint: 'Мы обсуждаем шаги, которые позволяют нам получить данные, чтобы их можно было проанализировать, а не конкретные действия в магазине.',
                        },
                        {
                            title: 'Чтобы выявлять общие тенденции и закономерности',
                            isValid: true,
                            hint: 'Действительно, агрегация и объединение продуктов в группы позволяет легко оперировать большими массивами данных, выявляя общие тенденции и закономерности.',
                        },
                    ],
                },
            ],
        },
        {
            content: {
                index: '03',
                title: 'Состояния категории и работа с базовыми индикаторами',
                videoId: 'udmQyZcTmvbum1BW91izB5',
            },
            questions: [
                {
                    id: 'lesson-3-01',
                    type: 'single',
                    content: '',
                    title: 'Снижение какой метрики сигнализирует о необходимости проведения анализа конкурентов?',
                    answers: [
                        {
                            title: 'Уменьшение среднего чека',
                            isValid: false,
                            hint: 'Причины снижения этой метрики могут быть разными.',
                        },
                        {
                            title: 'Снижение доли в категории',
                            isValid: true,
                            hint: 'Действительно, если доля снизилось, надо обязательно посмотреть, что происходит у конкурентов.',
                        },
                        {
                            title: 'Сокращение частоты покупки',
                            isValid: false,
                            hint: 'Причины снижения этой метрики могут быть разными.',
                        },
                    ],
                },
                {
                    id: 'lesson-3-02',
                    type: 'single',
                    content: '',
                    title: 'Что делать, если нет роста категории?',
                    answers: [
                        {
                            title: 'Выявить растущие продуктовые сегменты, где наши товары могут быть представлены',
                            isValid: true,
                            hint: 'Действительно, это оптимальное решение.',
                        },
                        {
                            title: 'Ничего, стабильность — признак мастерства',
                            isValid: false,
                            hint: 'Это опасная иллюзия, ведь вслед за такого рода стабильностью, может прийти снижение продаж.',
                        },
                        {
                            title: 'Повысить цену на продукт, раз его сейчас хорошо покупают, значит и по более высокой цене продолжат покупать',
                            isValid: false,
                            hint: 'Это рискованное решение, если оно не подтверждается большим количеством данных, и оно может дать обратный эффект.',
                        },
                    ],
                },
                {
                    id: 'lesson-3-03',
                    type: 'single',
                    content: '',
                    title: 'Что нам поможет, когда теряем долю на падающем рынке?',
                    answers: [
                        {
                            title: 'Интенсивнее рекламировать продукт и надеяться, что рынок сам восстановится',
                            isValid: false,
                            hint: 'Это весьма затратное решение, эффективность которого сложно предсказать точно в цифрах. И в любом случае, оно не может быть первым принятым шагом при потере доли.',
                        },
                        {
                            title: 'Анализ факторов, способствовавших росту конкурента на фоне отрицательной динамики категории, а также причин падения нашего продукта',
                            isValid: true,
                            hint: 'Действительно, прежде чем принимать какие-то шаги, нужно проанализировать ситуацию с разных сторон.',
                        },
                        {
                            title: 'Разработка новой маркетинговой стратегии и продуктовой линейки, учитывая потребности рынка',
                            isValid: false,
                            hint: 'Принятие такого решения может быть следующим шагом, но не первым.',
                        },
                    ],
                },
                {
                    id: 'lesson-3-04',
                    type: 'single',
                    content: '',
                    title: 'Какая аналитическая поддержка требуется при запуске нового продукта?',
                    answers: [
                        {
                            title: 'Достаточно обычных отчетов об отгрузках, все остальное придет в процессе',
                            isValid: false,
                            hint: 'Этих данных недостаточно, потому что они не отображают динамику по продукту.',
                        },
                        {
                            title: 'Мониторить отзывы покупателей в соцсетях',
                            isValid: false,
                            hint: 'Этих данных недостаточно, потому что они не отображают данные по продукту в конкретных цифрах.',
                        },
                        {
                            title: 'Регулярно отслеживать показатели нового продукта в сравнении с категорией и конкурентами, а также сравнивать c предыдущими запусками',
                            isValid: true,
                            hint: 'Действительно, при запуске нового продукта важно следить за динамикой продаж и анализировать текущую ситуацию, именно так можно оперативно корректировать свои действия.',
                        },
                    ],
                },
            ],
        },
        {
            content: {
                index: '04',
                title: 'Факторы роста или падения оборота',
                videoId: '17jLfZTyjCky8SBHg49nCp',
            },
            questions: [
                {
                    id: 'lesson-4-01',
                    type: 'single',
                    content: '',
                    title: 'Какие факторы определяют изменение оборота в рублях?',
                    answers: [
                        {
                            title: 'Количество покупателей',
                            isValid: false,
                            hint: 'На изменение оборота влияет не только этот фактор.',
                        },
                        {
                            title: 'Средняя цена',
                            isValid: false,
                            hint: 'На изменение оборота влияет не только этот фактор.',
                        },
                        {
                            title: 'Частота покупки',
                            isValid: false,
                            hint: 'На изменение оборота влияет не только этот фактор.',
                        },
                        {
                            title: 'Средний чек',
                            isValid: false,
                            hint: 'На изменение оборота влияет не только этот фактор.',
                        },
                        {
                            title: 'Всё вышеперечисленное',
                            isValid: true,
                            hint: 'Действительно, все эти факторы определяют изменение оборота. Анализируя эти параметры, мы можем оценивать их влияние на продажи уже запущенных продуктов и новинок.',
                        },
                    ],
                },
                {
                    id: 'lesson-4-02',
                    type: 'single',
                    content: '',
                    title: 'Что может случиться, когда рост продаж вызван только ростом стоимости, а категория увеличивается в натуральном объеме?',
                    answers: [
                        {
                            title: 'Мы приобретем новую аудиторию, заинтересованную премиальных товарах',
                            isValid: false,
                            hint: 'Это не способ приобретения новой аудитории. Премиальность товара не определяется только его ценой, и главное, нас могут поджидать серьёзные риски.',
                        },
                        {
                            title: 'Благодаря росту цен улучшатся наши индикаторы доходности',
                            isValid: false,
                            hint: 'Это не способ улучшить индикаторы доходности, потому что здесь есть очень серьёзные риски.',
                        },
                        {
                            title: 'В какой-то момент можно перейти ценовой порог, и это приведёт к сильному падению спроса на наш продукт',
                            isValid: true,
                            hint: 'Действительно, риск сильного падения спроса очень высок. Именно такие риски можно своевременно увидеть благодаря анализу данных.',
                        },
                    ],
                },
                {
                    id: 'lesson-4-03',
                    type: 'single',
                    content: '',
                    title: 'Какие неценовые факторы позволяют стимулировать спрос на товары?',
                    answers: [
                        {
                            title: 'Увеличение трафика через расширение доступности',
                            isValid: false,
                            hint: 'Это важный фактор. Но это не единственный фактор, позволяющий стимулировать спрос.',
                        },
                        {
                            title: 'Размещение продукта в торговых точках не только на основной полке категории',
                            isValid: false,
                            hint: 'Это важный фактор. Но это не единственный фактор, позволяющий стимулировать спрос.',
                        },
                        {
                            title: 'Увеличение частоты покупки и среднего чека с помощью специальных промо',
                            isValid: false,
                            hint: 'Это важный фактор. Но это не единственный фактор, позволяющий стимулировать спрос.',
                        },
                        {
                            title: 'Всё вышеперечисленное',
                            isValid: true,
                            hint: 'Действительно, все эти факторы могут стимулировать спрос. Именно аналитика может показать, какие оказываются наиболее эффективными.',
                        },
                    ],
                },
                {
                    id: 'lesson-4-04',
                    type: 'single',
                    content: '',
                    title: 'На какие ключевые покупательские метрики следует обращать внимание при анализе новинок?',
                    answers: [
                        {
                            title: 'Показатели первой и повторной покупки',
                            isValid: true,
                            hint: 'Действительно, очень важно сравнивать показатели первой и повторной покупки. И принимать решение в зависимости от той ситуации, которую видим.',
                        },
                        {
                            title: 'Количество поставок и списаний',
                            isValid: false,
                            hint: 'Эти данные важны, но они не относятся к покупательским метрикам.',
                        },
                        {
                            title: 'Количество возвратов нового товара',
                            isValid: false,
                            hint: 'Эти данные важны, но они не являются ключевыми метриками.',
                        },
                    ],
                },
                {
                    id: 'lesson-4-05',
                    type: 'single',
                    content: '',
                    title: 'Как определить, как повлиял новый запуск на развитие категории?',
                    answers: [
                        {
                            title: 'Оценить, как изменилась наша доля рынка в категории в сравнении с конкурентами',
                            isValid: false,
                            hint: 'В данном случае это не лучшая идея. Ведь на изменение доли рынка могли оказали влияние другие факторы.',
                        },
                        {
                            title: 'Проанализировать количество новых клиентов, пришедших именно за новинкой',
                            isValid: false,
                            hint: 'Действительно, лучше всего посмотреть, сколько новых клиентов пришли за новинкой. Отлично, когда аналитика предлагает такую возможность!',
                        },
                        {
                            title: 'Сравнить размер среднего чека до и после запуска',
                            isValid: true,
                            hint: 'В данном случае это не лучшая идея. Ведь на изменение среднего чека могли оказали влияние другие факторы.',
                        },
                    ],
                },
            ],
        },
        {
            content: {
                index: '05',
                title: 'Заявка на демо Dialog X5 Insights',
                status: 1,
                videoId: '_',
            },
            questions: [
                {
                    id: 'NeedDemo',
                    type: 'single',
                    content: '',
                    title: 'Хотите мы проведем демо?',
                    answers: [
                        {
                            title: 'да',
                            isValid: true,
                            hint: 'Отлично, мы скоро свяжемся с вами',
                        },
                        {
                            title: 'Нет',
                            isValid: false,
                            hint: 'Мы свяжемся с вами, чтобы узнать почему вы отказались',
                        },
                    ],
                },
            ],
        },
    ],
    outro: [
        {
            content: {
                index: '01',
                title: 'Обратная связь',
                description:
                    'Что бы вы хотели видеть в более продвинутых уровнях?',
            },
            questions: [
                {
                    id: 'feedback-01',
                    type: 'single',
                    content: '',
                    title: 'Больше материалов в видео-формате:',
                    answers: [
                        {
                            title: '1',
                            isValid: true,
                        },
                        {
                            title: '2',
                            isValid: true,
                        },
                        {
                            title: '3',
                            isValid: true,
                        },
                        {
                            title: '4',
                            isValid: true,
                        },
                        {
                            title: '5',
                            isValid: true,
                        },
                    ],
                },
                {
                    id: 'feedback-02',
                    type: 'single',
                    content: '',
                    title: 'Больше материалов в формате кейсов:',
                    answers: [
                        {
                            title: '1',
                            isValid: true,
                        },
                        {
                            title: '2',
                            isValid: true,
                        },
                        {
                            title: '3',
                            isValid: true,
                        },
                        {
                            title: '4',
                            isValid: true,
                        },
                        {
                            title: '5',
                            isValid: true,
                        },
                    ],
                },
                {
                    id: 'feedback-03',
                    type: 'single',
                    content: '',
                    title: 'Больше теоретических материалов:',
                    answers: [
                        {
                            title: '1',
                            isValid: true,
                        },
                        {
                            title: '2',
                            isValid: true,
                        },
                        {
                            title: '3',
                            isValid: true,
                        },
                        {
                            title: '4',
                            isValid: true,
                        },
                        {
                            title: '5',
                            isValid: true,
                        },
                    ],
                },
                {
                    id: 'feedback-04',
                    type: 'single',
                    content: '',
                    title: 'Оцените ваш опыт работы с курсом:',
                    answers: [
                        {
                            title: '1',
                            isValid: true,
                        },
                        {
                            title: '2',
                            isValid: true,
                        },
                        {
                            title: '3',
                            isValid: true,
                        },
                        {
                            title: '4',
                            isValid: true,
                        },
                        {
                            title: '5',
                            isValid: true,
                        },
                    ],
                },
                {
                    id: 'feedback-05',
                    type: 'text',
                    content: '',
                    title: 'Если у вас есть комментарии, вы можете написать их ниже:',
                    hint: 'Текст комментария',
                },
            ],
        },
    ],
}
