import Mechanics from 'src/components/mechanics'
import Lessons from 'src/components/lessons'
import Cases from 'src/components/cases'
import Materials from 'src/components/materials'
import HeroCourse from 'src/components/hero/hero-course'
import { COURSE } from 'src/data'
import { useAuth } from 'src/lib/providers/auth'
import { Navigate } from 'react-router'
import PathConstants from 'src/routes/path-constants'
import Feedback from 'src/components/feedback'
import { useRef } from "react";

export default function Course() {
    const { userId } = useAuth()
    const materialsRef = useRef(null);

    if (!userId) return <Navigate to={PathConstants.HOME} />



    const { cases, lessons } = COURSE
    return (
        <div>
            <HeroCourse />
            <Mechanics />
            <Lessons data={lessons} materialsRef={materialsRef}/>
            <Cases materialsRef={materialsRef} data={cases} />
            <Materials ref={materialsRef}/>
            <Feedback />
        </div>
    )
}
