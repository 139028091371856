import { useModal } from 'src/lib/providers/modal'
import styles from './style.module.css'
import { PassedIcon } from 'src/assets/images/passed-icon'
import Button from 'src/components/button'
import { useAuth } from 'src/lib/providers/auth'

const SuccessPopup = () => {
    const { closeModal, context } = useModal();
    const { loadAnswers } = useAuth();

    const isMobile = window.innerWidth < 768;

    function close() {
        closeModal();

    }
    const onClickUsefulMaterials = () => {
        context?.onClickUsefulMaterials()
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.icon}>
                    <PassedIcon />
                </div>
                <h2>Отлично! Вы&nbsp;демонстрируете невероятный прогресс!</h2>
                <p>
                Продолжайте изучать материалы про&nbsp;аналитику данных
                и&nbsp;не&nbsp;забывайте применять полученные знания на&nbsp;практике.
                </p>
                {isMobile && <Button onClick={close}>Продолжить</Button>}
            </div>
            <div className={styles.footer}>
                <div className={styles.footerText}>
                    Больше информации находится в&nbsp;разделе{' '}
                    <a onClick={onClickUsefulMaterials}>Полезные&nbsp;материалы</a>
                </div>

                {!isMobile && <Button onClick={close}>Продолжить</Button>}
            </div>
        </div>
    )
}
export default SuccessPopup
