import styles from './style.module.css'
import Button from 'src/components/button'
import {MODAL} from "../layouts/layout";
import { useModal } from 'src/lib/providers/modal'

const Step = ({ number, title, description }) => (
    <div className={styles.step}>
        <div className={styles.stepNumber}>{number}</div>
        <div className={styles.stepContent}>
            <div className={styles.stepTitle}>{title}</div>
            <div className={styles.stepDescription}>{description}</div>
        </div>
    </div>
)

const AboutCourse = () => {
    const { openModal } = useModal()
    const steps = [
        {
            number: '01',
            title: 'Анализ',
            description:
                'Узнайте, как провести базовый анализ категории и продаж вашего бренда.',
        },
        {
            number: '02',
            title: 'Основы',
            description:
                'Погрузитесь в важные детали уравнения категории и основных метрик.',
        },
        {
            number: '03',
            title: 'Параметры',
            description:
                'Посмотрите, как основные состояния и ключевые параметры помогают принимать эффективные решения.',
        },
        {
            number: '04',
            title: 'Тесты',
            description:
                'Проверьте, как работают знания, на реальных ситуациях с данными Dialog.X5 Insights.',
        },
    ]
    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                Пройдите обучающий курс, чтобы лучше понимать, как получать
                максимум полезной информации от&nbsp;работы с&nbsp;данными
            </div>

            <div className={styles.steps}>
                {steps.map((step, index) => (
                    <div key={index} className={styles[`div${index + 1}`]}>
                        <Step {...step} />
                    </div>
                ))}

                <div className={styles.div5}>
                    <div className={styles.beginCourse}>
                        <div>
                            Курс включает в себя интерактивные видеоуроки,
                            практические задания и учебные материалы, которые
                            помогут вам развить навыки в области аналитики
                            данных. Присоединяйтесь к Академии Dialog X5 и
                            станьте настоящим экспертом в анализе данных!
                        </div>
                        <Button variant="white" onClick={() => openModal(MODAL.REGISTER)}>Начать</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutCourse
