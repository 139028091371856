import styles from './style.module.css'
import Question from 'src/components/question'
import { useModal } from 'src/lib/providers/modal'

import { useRef, useState } from 'react'
import { MODAL } from 'src/components/layouts/layout'
import deepClone from 'src/lib/utils/deepClone'
import { useAuth } from 'src/lib/providers/auth'
import { actualizeQuestionsStatus } from './lesson-popup'
import convertToAnswer from 'src/lib/utils/convertToAnswer'

const CasePopup = () => {
    const { openModal, closeModal, context } = useModal()
    const qRef = useRef(null)
    const { sendAnswer, answers } = useAuth()

    const contentData = context?.data || {}
    const surveyId = context?.surveyId || 'default'

    if (!context) return closeModal()

    const [questions, setCurrentQuestions] = useState(
        actualizeQuestionsStatus(contentData.questions || [], answers),
    )

    const index = Number(contentData.content.index)

    const visibleContentParts = [
        ...questions.filter(item => item.alreadyAnswered === true),
        questions.find(
            item =>
                item.alreadyAnswered === false ||
                item.alreadyAnswered === undefined,
        ),
    ]

    const onSend = (question, selected, text) => {
        console.log('<><>', question, selected, text)
        sendAnswer(convertToAnswer(surveyId, question, { selected, text }))
    }

    const handleNext = question => {
        setCurrentQuestions(prev => {
            const cloned = deepClone(prev)

            cloned.find(c => {
                if (c.title === question.title) {
                    c.alreadyAnswered = true
                    return true
                }
                return false
            })

            if (
                cloned.length === cloned.filter(c => c.alreadyAnswered).length
            ) {
                openModal(MODAL.SUCCESS, {
                    onClickUsefulMaterials: onClickUsefulMaterials
                })
            }

            return cloned
        })

        window.setTimeout(() => {
            qRef?.current?.scrollIntoView({ behavior: 'smooth' })
        }, 100)
    }

    const onClickUsefulMaterials = () => {
        closeModal();
        context?.materialsRef?.current?.scrollIntoView({ behavior: "smooth" });
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.subHeading}>Кейс {index}</div>
                <div className={styles.title}>{contentData?.title}</div>
                <div className={styles.description}>
                    <p>{contentData.description}</p>
                    <div className={styles.descriptionCase}>
                        <p>
                            Перед вами кейс, основанный на реальных, но
                            обезличенных данных аналитики Х5. Вам нужно
                            разобраться с основными метриками, ответить на
                            вопросы и сформировать рекомендацию, релевантную
                            этому состоянию категории.
                        </p>
                        <p> Итак, начнём!</p>
                    </div>

                    <div className={styles.questions}>
                        {visibleContentParts.map(question => {
                            return (
                                question && (
                                    <div ref={qRef}>
                                        {question.content}

                                        <Question
                                            data={question}
                                            handleNext={handleNext}
                                            onSend={onSend}
                                        />
                                    </div>
                                )
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerText}>
                    Больше информации находится в&nbsp;разделе{' '}
                    <a onClick={onClickUsefulMaterials}>Полезные&nbsp;материалы</a>
                </div>
            </div>
        </div>
    )
}

export default CasePopup
