import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import useLocalStorage from '../hooks/localStorage'
import { useSearchParams } from 'react-router-dom';
import Keycloak from 'keycloak-js';
import env from "react-dotenv";
import Axios from 'axios';

function authRequestInterceptor(config) {
    if (config.headers) {
      config.headers.Accept = 'application/json';
    }

    config.withCredentials = true;
    return config;
  }

  export const api = Axios.create({
    baseURL: (env.API_URL || "") + "/v1",
  });

  api.interceptors.request.use(authRequestInterceptor);

  api.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      console.log("API ERROR:", error);
      // const message = error.response?.data?.message || error.message;
      // useNotifications.getState().addNotification({
      //   type: 'error',
      //   title: 'Error',
      //   message,
      // });

      // if (error.response?.status === 401) {
      //   const searchParams = new URLSearchParams();
      //   const redirectTo = searchParams.get('redirectTo');
      //   window.location.href = `/auth/login?redirectTo=${redirectTo}`;
      // }

      return Promise.reject(error);
    },
  );

const tokenAuthContext = {
    login: () => {},
    init: () => {},
    isLoading: true,
    userId: null,
};

const authContext = createContext(tokenAuthContext)

export function AuthProvider({ children }) {
    const auth = useProvideAuth()

    return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => useContext(authContext);

// console.log(">>>>>>>", env)

const keycloak = new Keycloak({
    url: env.KEYCLOAK_AUTH,
    realm: env.KEYCLOAK_REALM,
    clientId: env.KEYCLOAK_CLIENT
});

function useProvideAuth() {
    const [userId, setUserId] = useLocalStorage('userId', null);
    const [answers, setAnswers] = useState([]);
    const [isAnswersLoaded, setIsAnswersLoaded] = useState(false);

    const loadAnswers = useCallback(() => {
        load(userId);
    }, [userId]);

    async function load(userId) {
        const answers = await api.get('/answers/progress', { params: { userId } });
        console.log("received answers", answers);
        setAnswers(answers);
        setIsAnswersLoaded(true);
    }

    const sendAnswer = useCallback((answer) => {
        return api.post('/answers', { userId, ...answer });
    }, [userId]);

    function login() {
        keycloak.login();
    }

    async function init() {
        if (!keycloak.didInitialize) {
            console.log("init: initialize keycloak");
            try {
                await keycloak.init({
                    onLoad: 'check-sso',
                    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
                    checkLoginIframe: false
                });
            } catch(e) {
                console.log("Failed to run keycloak.init", e);
            }
        }

        if (!userId && keycloak.subject) {
            console.log("init: got keycloak.subject", keycloak.subject);
            setUserId(keycloak.subject);
            load(keycloak.subject);
        } else if (userId) {
            load(userId);
        }

        // if (userId && !keycloak.subject) {
        //     console.log("init: got empty keycloak.subject", keycloak.subject);
        //     setUserId(null);
        // }
    }

    return {
        init,
        login,
        userId,
        sendAnswer,
        answers,
        loadAnswers,
        isAnswersLoaded
    }
}
