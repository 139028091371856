import styles from './style.module.css'
import Question from 'src/components/question'
import { useModal } from 'src/lib/providers/modal'

import VideoKinescope from 'src/components/video-kinescope'
import { useRef, useState } from 'react'
import { MODAL } from 'src/components/layouts/layout'
import deepClone from 'src/lib/utils/deepClone'
import { useAuth } from 'src/lib/providers/auth'
import convertToAnswer from 'src/lib/utils/convertToAnswer'

export function actualizeQuestionsStatus(questions, answers) {
    return questions.map(q => {
        q.alreadyAnswered = answers.find(a => a.questionId == q.id) != null
        return q
    })
}

const LessonPopup = () => {
    const { openModal, closeModal, context } = useModal()
    const { sendAnswer, answers } = useAuth()
    const qRef = useRef(null)

    const contentData = context?.data || {}
    const surveyId = context?.surveyId || 'default'

    if (!context) return closeModal()

    const [questions, setCurrentQuestions] = useState(
        actualizeQuestionsStatus(contentData.questions || [], answers),
    )

    const index = Number(contentData.content.index)

    const visibleContentParts = [
        ...questions.filter(item => item.alreadyAnswered === true),
        questions.find(
            item =>
                item.alreadyAnswered === false ||
                item.alreadyAnswered === undefined,
        ),
    ]

    const onSend = (question, selected, text) => {
        console.log('<><>', question, selected, text)
        sendAnswer(convertToAnswer(surveyId, question, { selected, text }))
    }

    const handleNext = question => {
        setCurrentQuestions(prev => {
            const cloned = deepClone(prev)

            cloned.find(c => {
                if (c.title === question.title) {
                    c.alreadyAnswered = true
                    return true
                }
                return false
            })

            if (
                cloned.length === cloned.filter(c => c.alreadyAnswered).length
            ) {
                openModal(MODAL.SUCCESS, {
                    onClickUsefulMaterials: onClickUsefulMaterials
                })
            }

            return cloned
        })
        window.setTimeout(() => {
            qRef?.current?.scrollIntoView({ behavior: 'smooth' })
        }, 100)
    }

    const onClickUsefulMaterials = () => {
        closeModal();
        context?.materialsRef?.current?.scrollIntoView({ behavior: "smooth" });
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.subHeading}>Урок {index}</div>
                <div className={styles.title}>{contentData?.title}</div>
                <div className={styles.description}>
                    {contentData.content.videoId && contentData.content.videoId !== '_' && (
                        <VideoKinescope videoId={contentData.content.videoId} />
                    )}
                    {contentData.content.videoId !== '_' && <h2>Тест</h2>}
                    <div className={styles.questions}>
                        {visibleContentParts.map(question => {
                            return (
                                question && (
                                    <div ref={qRef}>
                                        {question.content}

                                        <Question
                                            data={question}
                                            handleNext={handleNext}
                                            onSend={onSend}
                                        />
                                    </div>
                                )
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerText}>
                    Больше информации находится в&nbsp;разделе{' '}
                    <a onClick={onClickUsefulMaterials}>Полезные&nbsp;материалы</a>
                </div>
            </div>
        </div>
    )
}

export default LessonPopup
